import React from 'react';
import styled from 'styled-components';
// import ListItemContainer from '../../components/ThankyouPages/ListItemContainer';
// import AdButler from '../../components/ThankyouPages/AdButler';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

// prettier-ignore
// const adIds = ['483835', '483836', '483837', '483838', '483839', '483840', '483841', '483842'];

const IntroBlock = styled.div`
  box-sizing: border-box;
  background: #213469;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  max-width: 980px;
  h1 {
    font-size: 28px;
    font-weight: 300;
    margin: 0;
    @media (max-width: 801px) {
      font-size: 15px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 400;
  }
  .ybm {
    font-size: 44px;
    font-weight: 500;
    @media (max-width: 801px) {
      font-size: 26px;
    }
  }
`;
const EccmContainer = styled.div`
  font-family: 'Roboto', Arial, san-serif;
  width: calc(100% - 20px);
  max-width: 1170px;
  margin: auto;
  img {
    margin: 0;
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
  }
  section {
    box-sizing: border-box;
    width: calc(100vw - 40px);
    max-width: 700px;
    padding: 10px 20px 10px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 3px 8px #555;
    margin-bottom: 10px;
    @media (max-width: 801px) {
      padding: 10px;
    }
  }
  .contactInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 980px;
    margin: auto;
    .phoneNumber {
      background: #18569d;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      a {
        color: #fff;
      }
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    .clickApply {
      height: 49px;
      background: #18569d;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    @media (max-width: 801px) {
      flex-wrap: wrap;
    }
  }
  .logoContainer {
    width: calc(100% - 20px);
    margin: 0 auto;
    max-width: 400px;
    padding: 20px 0;
  }

  .questions {
    box-sizing: border-box;
    background: #e2e5ec;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    max-width: 980px;
    color: #0f2030;
    h3 {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      .bold {
        font-weight: 800;
        text-decoration: underline;
        text-decoration-color: #07956e;
        text-decoration-thickness: 2px;
      }
    }
    .nmlsBlock {
      padding-top: 20px;
    }
    .nmls {
      font-size: 16px;
      text-align: center;
      padding-bottom: 0;
    }
    .threeItemBlock {
      margin-top: 20px;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      .contentBlock {
        width: 31%;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        p {
          font-size: 13px;
          font-weight: 500;
          width: 100%;
          margin-top: 5px;
          margin-bottom: 14px;
          .green {
            color: green;
          }
          .bold {
            font-weight: 900;
          }
        }
        .iconContainer {
          width: 65px;
        }
        @media (max-width: 801px) {
          width: 100%;
        }
      }
      @media (max-width: 801px) {
        flex-wrap: wrap;
      }
    }
    .dividerContainer {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(to bottom, #c3cde3 0%, #8998b7 100%);
      color: #fff;
      padding: 8px;
      border-radius: 10px;
      margin-bottom: 10px;
      text-align: center;
      h2 {
        font-family: 'Roboto', Arial, san-serif;
        font-weight: 400;
        text-shadow: 0 3px 6px #aaa;
        margin: 0;
        font-size: 30px;
        @media (max-width: 801px) {
          font-size: 15px;
        }
      }
    }
    .questionList {
      ul {
        display: block;
        column-count: 2;
        column-gap: 20px;
        font-size: 14px;
        font-weight: 600;
        padding-left: 5px;
        @media (max-width: 801px) {
          column-count: 1;
        }
        li {
          list-style-type: none;
          margin-bottom: 10px;
          background: url('https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/eccmDisc.png')
            0 4px/12px no-repeat;
          padding-left: 20px;
          font-weight: 600;
          font-style: italic;
        }
      }
    }
    .disclaimer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 10px;
        margin-left: 20px;
      }
      a {
        color: #2863a2;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 801px) {
    width: 100%;
    .ameriSave .no-bs .container .contactInfo {
      flex-wrap: wrap;
    }
  }
`;

const EccmThankyou = () => (
  <>
    <EccmContainer>
      <IntroBlock>
        <h1>
          <span className="ybm">You’ve been matched</span>
          <br />
          with our featured provider, CROSSCOUNTRY MORTGAGE
        </h1>
      </IntroBlock>
      <div className="logoContainer">
        <img
          src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/eccmLogo.png"
          alt="Strong Home Mortgage"
        />
      </div>
      <section className="questions">
        <h3>
          Much of our success can be attributed to the fact that we’ve never
          looked at ourselves as being in the mortgage business because we’re in
          the people business; this approach of putting people first has allowed
          us to become one of the most trusted and highest rated mortgage
          lenders in America with nearly{' '}
          <span className="bold">15,000 Five-Star Reviews on Zillow.</span>
        </h3>
        <div className="threeItemBlock">
          <div className="contentBlock">
            <div className="iconContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/eccmHomeIcon.png"
                alt="Home Icon"
              />
            </div>
            <p>
              We’re the go-to lender for those seeking{' '}
              <span className="bold">
                better loan options, great rates and fast closings!
              </span>
              <span className="green">
                {' '}
                Closing loans in as little as 21 days
              </span>
            </p>
          </div>
          <div className="contentBlock">
            <div className="iconContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/eccmInstitutionIcon.png"
                alt="Institution Icon"
              />
            </div>
            <p>
              Going the extra mile for our clients has placed us in the{' '}
              <span className="bold">Top 10 Mortgage Lenders in America</span> -
              Licensed in all 50 states with 500 branch locations!
            </p>
          </div>
          <div className="contentBlock">
            <div className="iconContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/eccmMoneyIcon.png"
                alt="Money Icon"
              />
            </div>
            <p>
              We can’t wait to earn your trust and exceed your expectations by
              providing you with the best mortgage for your specific needs
            </p>
          </div>
        </div>
        <div className="dividerContainer">
          <div>
            <h2>What Can You Expect From CrossCountry Mortgage?</h2>
          </div>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/mooQuestionIcon.png"
            alt="Question Icon"
          />
        </div>
        <div className="questionList">
          <ul>
            <li>Great rates and terms.</li>
            <li>Available by Phone 24/7.</li>
            <li>Hassle-Free Rate Quotes.</li>
            <li>Easy Online Application Process.</li>
            <li>Closing Loans in as little as 21 days.</li>
            <li>World-Class Customer Service.</li>
            <li>Full Transparency from Beginning to End</li>
            <li>Same Day Pre-Qualifications.</li>
          </ul>
        </div>
        {/* <div className="nmlsBlock">
          <p className="nmls">Strong Home Mortgage, NMLS #1675638</p>
        </div> */}
      </section>
    </EccmContainer>
  </>
);

export default withThankyouWrapper(EccmThankyou);
